import * as React from "react"

export default function LightCTA(props) {
    return (
      <div className={props.bgLite}>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Ready for a beautiful site?</span>
            <span className={`block ${props.textBold}`}>Start your free conultation today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="mailto:carter.m.rink@gmail.com"
                className={`inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white ${props.bgBold} hover:${props.hoverBold}`}
              >
                Get started
              </a>
            </div>

          </div>
        </div>
      </div>
    )
  }
  
  