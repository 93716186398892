const Blue = {
    color: "blue",
    textBold: "text-blue-600",
    textBolder: "text-blue-700",
    textMd: "text-blue-500",
    focusColor: "ring-blue-500",
    bgLite: "bg-blue-50",
    bgLtMd: "bg-blue-100",
    bgMid: "bg-blue-200",
    bgBold: "bg-blue-600", 
    bgMd: "bg-blue-500",
    hoverBold: "bg-blue-700",
    borderColor: "border-blue-500"
}

const Red = {
    color: "red",
    textBold: "text-red-600",
    textBolder: "text-red-700",
    textMd: "text-red-500",
    focusColor: "ring-red-500",
    bgLite: "bg-red-50",
    bgLtMd: "bg-red-100",
    bgMid: "bg-red-200",
    bgBold: "bg-red-600", 
    bgMd: "bg-red-500",
    hoverBold: "bg-red-700",
    borderColor: "border-red-500"
}

const Green = {
    color: "green",
    textBold: "text-green-600",
    textBolder: "text-green-700",
    textMd: "text-green-500",
    focusColor: "ring-green-500",
    bgLite: "bg-green-50",
    bgLtMd: "bg-green-100",
    bgMid: "bg-green-200",
    bgBold: "bg-green-600", 
    bgMd: "bg-green-500",
    hoverBold: "bg-green-700",
    borderColor: "border-green-500"
}


export {Blue, Red, Green}