/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function SlantHero(props) {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#">
                      <span className="sr-only">Workflow</span>

                      
                      <svg className="h-8 w-auto"
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
                        <path fill={props.color} d="M1.14266 11.4855C1.42681 11.0119 2.04107 10.8584 2.51465 11.1425L12.0002 16.8338L21.4857 11.1425C21.9592 10.8584 22.5735 11.0119 22.8576 11.4855C23.1418 11.9591 22.9882 12.5733 22.5146 12.8575L12.5146 18.8575C12.198 19.0475 11.8023 19.0475 11.4857 18.8575L1.48566 12.8575C1.01208 12.5733 0.85851 11.9591 1.14266 11.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M1.14266 15.4855C1.42681 15.0119 2.04107 14.8584 2.51465 15.1425L12.0002 20.8338L21.4857 15.1425C21.9592 14.8584 22.5735 15.0119 22.8576 15.4855C23.1418 15.9591 22.9882 16.5733 22.5146 16.8575L12.5146 22.8575C12.198 23.0475 11.8023 23.0475 11.4857 22.8575L1.48566 16.8575C1.01208 16.5733 0.85851 15.9591 1.14266 15.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M22 8L12 14L2 8L12 2L22 8Z" undefined="1"></path>
                        <path fill={props.color} d="M11.4855 1.14251C11.8022 0.952498 12.1978 0.952498 12.5145 1.14251L22.5145 7.14251C22.8157 7.32323 23 7.64874 23 8C23 8.35126 22.8157 8.67677 22.5145 8.85749L12.5145 14.8575C12.1978 15.0475 11.8022 15.0475 11.4855 14.8575L1.4855 8.85749C1.1843 8.67677 1 8.35126 1 8C1 7.64874 1.1843 7.32323 1.4855 7.14251L11.4855 1.14251ZM3.94365 8L12 12.8338L20.0563 8L12 3.16619L3.94365 8Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        </svg>


                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:${props.focusColor}`}>
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                  <a href="#" className={`font-medium ${props.textBold} hover:${props.textMd}`}>
                    Log in
                  </a>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>


                    <svg className="h-8 w-auto"
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="">
                        <path fill={props.color} d="M1.14266 11.4855C1.42681 11.0119 2.04107 10.8584 2.51465 11.1425L12.0002 16.8338L21.4857 11.1425C21.9592 10.8584 22.5735 11.0119 22.8576 11.4855C23.1418 11.9591 22.9882 12.5733 22.5146 12.8575L12.5146 18.8575C12.198 19.0475 11.8023 19.0475 11.4857 18.8575L1.48566 12.8575C1.01208 12.5733 0.85851 11.9591 1.14266 11.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M1.14266 15.4855C1.42681 15.0119 2.04107 14.8584 2.51465 15.1425L12.0002 20.8338L21.4857 15.1425C21.9592 14.8584 22.5735 15.0119 22.8576 15.4855C23.1418 15.9591 22.9882 16.5733 22.5146 16.8575L12.5146 22.8575C12.198 23.0475 11.8023 23.0475 11.4857 22.8575L1.48566 16.8575C1.01208 16.5733 0.85851 15.9591 1.14266 15.4855Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        <path fill={props.color} d="M22 8L12 14L2 8L12 2L22 8Z" undefined="1"></path>
                        <path fill={props.color} d="M11.4855 1.14251C11.8022 0.952498 12.1978 0.952498 12.5145 1.14251L22.5145 7.14251C22.8157 7.32323 23 7.64874 23 8C23 8.35126 22.8157 8.67677 22.5145 8.85749L12.5145 14.8575C12.1978 15.0475 11.8022 15.0475 11.4855 14.8575L1.4855 8.85749C1.1843 8.67677 1 8.35126 1 8C1 7.64874 1.1843 7.32323 1.4855 7.14251L11.4855 1.14251ZM3.94365 8L12 12.8338L20.0563 8L12 3.16619L3.94365 8Z" clip-rule="evenodd" fill-rule="evenodd" undefined="1"></path>
                        </svg>


                    </div>
                    <div className="-mr-2">
                      <Popover.Button className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:${props.focusColor}`}>
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <a
                    href="#"
                    className={`block w-full px-5 py-3 text-center font-medium ${props.textBold} bg-gray-50 hover:bg-gray-100`}
                  >
                    Log in
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Data to enrich your</span>{' '}
                <span className={`block ${props.textBold} xl:inline`}>online classrooms</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
                fugiat veniam occaecat fugiat aliqua.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#"
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white ${props.bgBold} hover:${props.hoverBold} md:py-4 md:text-lg md:px-10`}
                  >
                    Get started
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#"
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md ${props.textBolder} ${props.bgLtMd} hover:${props.bgMid} md:py-4 md:text-lg md:px-10`}
                  >
                    Live demo
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1596495578065-6e0763fa1178?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80"
          alt=""
        />
      </div>
    </div>
  )
}
