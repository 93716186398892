import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Green } from "../data/color"

import SlantHero from "../components/heros/slantHero"
import SideBySideImgFeature from "../components/features/sideBySideImgFeature"
import LightCTA from "../components/ctas/lightCTA"
import ThreeColFAQ from "../components/faqs/threeColFAQ"
import FourColFooter from "../components/footers/fourColFooter"




const SecondPage = () => (
  <div>

  <SlantHero color={Green.color} focusColor={Green.focusColor} textBold={Green.textBold} textMd={Green.textMd} bgBold={Green.bgBold} hoverBold={Green.hoverBold} textBolder={Green.textBolder} bgLtMd={Green.bgLtMd} bgMid={Green.bgMid}  />

  <SideBySideImgFeature color={Green.color} bgMd={Green.bgMd} />

  <LightCTA color={Green.pallet} bgLite={Green.bgLite} bgBold={Green.bgBold} textBold={Green.textBold} hoverBold={Green.hoverBold} />
  <ThreeColFAQ />
  <FourColFooter color={Green.color} />
</div>
  /*
  <Layout>
    <Seo title="Page two" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
  */
)

export default SecondPage
